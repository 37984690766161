import axios from 'axios';
import accountService from './account';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const createAxiosClient = () => {
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_API,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });

  // Request interceptor
  axiosClient.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Refresh token logic
  const refreshAuthLogic = async (failedRequest) => {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      const currentAccessToken = localStorage.getItem('accessToken');
      
      if (!refreshToken || !currentAccessToken) {
        return Promise.reject('No refresh token or access token available');
      }

      const tokenRefreshResponse = await accountService.refreshToken(refreshToken, {
        headers: {
          'Authorization': currentAccessToken
        }
      });
      
      const { accessToken, refreshToken: newRefreshToken } = tokenRefreshResponse.data;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', newRefreshToken);
      
      failedRequest.response.config.headers['Authorization'] = accessToken;
      
      return Promise.resolve();
    } catch (error) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      // You might want to redirect to login page here
      return Promise.reject(error);
    }
  };

  createAuthRefreshInterceptor(axiosClient, refreshAuthLogic, {
    statusCodes: [401], // Array of status codes which trigger the refresh flow
  });

  return axiosClient;
};

const axiosClient = createAxiosClient();
export default axiosClient;