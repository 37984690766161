import React, { useContext, useState } from 'react';
import accountService from '../api/account';
import {
    Box,
    Container,
    TextField,
    Button,
    Typography,
    Paper,
    Link,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AuthContext } from '../api/AuthContext';

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const { updateAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [loginError, setLoginError] = useState('');

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        if(loginError !== '')
            setLoginError('')

        if (name === 'email') {
            if (!value) {
                setEmailError('Email is required');
            } else if (!validateEmail(value)) {
                setEmailError('Please enter a valid email');
            } else {
                setEmailError('');
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!emailError && validateEmail(formData.email)) {
            try {
                const response = await accountService.login(formData.email, formData.password);
                const { accessToken, refreshToken } = response.data;
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);
                updateAuth(accessToken, refreshToken);

                navigate("/");
            } catch (e) {
                if(e.response != null)
                if (e.response.status === 403) {
                    setLoginError('Invalid username or password');
                } else {
                    console.log('Error:', e);
                }
            }
        }
    };

    return (
        <Box sx={{ 
            py: 8,
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            bgcolor: 'background.default'
        }}>
            <Container maxWidth="sm">
                <Box sx={{textAlign: 'center' }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Welcome Back
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
                        Please enter your details to sign in to your account
                    </Typography>
                </Box>
                
                <Paper 
                    elevation={3} 
                    sx={{ 
                        p: 3,
                        borderRadius: 2,
                        bgcolor: 'background.paper',
                        '&:hover': { 
                            boxShadow: 6
                        }
                    }}
                >
                    <Box 
                        component="form" 
                        onSubmit={handleSubmit}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 4
                        }}
                    >
                        <Typography variant="h4" component="h2" align="center" gutterBottom>
                            Login
                        </Typography>

                        {loginError && (
                            <Typography variant="body2" color="error" align="center">
                                {loginError}
                            </Typography>
                        )}

                        <TextField
                            required
                            name="email"
                            label="Email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            autoComplete="email"
                            error={!!emailError}
                            helperText={emailError}
                            fullWidth
                            sx={{ 
                                '& .MuiInputBase-root': {
                                    color: 'text.primary',
                                },
                                '& .MuiFormLabel-root': {
                                    color: 'text.secondary',
                                },
                                '& .MuiFormHelperText-root': {
                                    color: 'text.secondary',
                                }
                            }}
                        />

                        <TextField
                            required
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            value={formData.password}
                            onChange={handleChange}
                            autoComplete="current-password"
                            fullWidth
                            sx={{ 
                                '& .MuiInputBase-root': {
                                    color: 'text.primary',
                                },
                                '& .MuiFormLabel-root': {
                                    color: 'text.secondary',
                                },
                                '& .MuiFormHelperText-root': {
                                    color: 'text.secondary',
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Box sx={{ textAlign: 'right' }}>
                            <Link 
                                component={RouterLink} 
                                to="/forgot-password" 
                                color="primary" 
                                variant="body2"
                                sx={{ 
                                    textDecoration: 'none',
                                    '&:hover': {
                                        textDecoration: 'underline'
                                    }
                                }}
                            >
                                Forgot password?
                            </Link>
                        </Box>

                        <Button 
                            type="submit" 
                            variant="contained" 
                            fullWidth 
                            sx={{ 
                                height: '56px',
                                fontSize: '1.1rem',
                                textTransform: 'none',
                                color: (theme) => theme.palette.mode === 'light' ? 'white' : 'black'
                            }}
                        >
                            Sign in
                        </Button>

                        <Box sx={{ 
                            textAlign: 'center',
                            paddingTop: 2,
                            borderTop: 1,
                            borderColor: 'divider'
                        }}>
                            <Typography variant="body2" color="text.secondary">
                                Don't have an account?{' '}
                                <Link 
                                    component={RouterLink} 
                                    to="/signup" 
                                    color="primary"
                                    sx={{ 
                                        textDecoration: 'none',
                                        '&:hover': {
                                            textDecoration: 'underline'
                                        }
                                    }}
                                >
                                    Sign up
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
};

export default Login;