import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';

const ProtectedRoutes = ({ element: Component, ...rest }) => {
    const { auth, fetchNewRefreshToken } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()

    useEffect(() => {
        const handleAuth = async () => {
            if (auth.accessToken) {
                setLoading(false);
                return;
            }
            navigate('/login')
        };

        handleAuth();
    }, [auth.accessToken, fetchNewRefreshToken]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return auth.accessToken ? <Component {...rest} /> : null;
};

export default ProtectedRoutes;
