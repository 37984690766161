import React, { useState, useEffect } from 'react';
import { Box, List, Drawer, ListItemIcon, ListItemText, ListItemButton, Typography, IconButton, useTheme, useMediaQuery, Button } from '@mui/material';
import {
    AccessTime as TimeIcon,
    BarChart as ExpenseIcon,
    Payment as PaymentIcon,
    Description as InvoiceIcon,
    Groups as ClientsIcon,
    Gavel as MattersIcon,
    Assessment as ReportsIcon,
    PlaylistAdd as TimeEntryIcon,
    Search as SearchIcon,
    Receipt as NewExpenseIcon,
    AccountBalanceWallet as MakePaymentIcon,
    Security as TrustIcon,
    Refresh as ReplenishIcon,
} from '@mui/icons-material';
import { jwtDecode } from 'jwt-decode';
import AppBar from '../components/AppBar';
import DashboardContent from '../components/dashboard/DashboardContent';
import accountService from '../api/account';

const Dashboard = () => {
    const [selectedMainMenu, setSelectedMainMenu] = useState(null);
    const [selectedPath, setSelectedPath] = useState('/');
    const [permissions, setPermissions] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [mobileOpen, setMobileOpen] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            const decoded = jwtDecode(token);
            setPermissions(decoded.permissions);
        }
    }, []);

    const hasPermission = (permission) => {
        return permissions.includes('all_system_access') || permissions.includes(permission);
    };

    const getMenuItems = () => {
        const items = [];

        // Check Permissions for Clients & Matters Tab
        if (hasPermission('clients_all') || hasPermission('clients_assigned') || hasPermission('matters_all') || hasPermission('matters_assigned')) {
            const subItems = [];

            // Client items
            if (hasPermission('clients_all') || hasPermission('clients_assigned')) {
                subItems.push({ id: 'clientsList', label: 'View Clients', icon: <SearchIcon />, path: '/clients/list' });
                if (hasPermission('clients_all')) {
                    subItems.push({ id: 'newClient', label: 'New Client', icon: <ClientsIcon />, path: '/clients/new' });
                }
            }

            // Matter items
            if (hasPermission('matters_all') || hasPermission('matters_assigned')) {
                subItems.push({ id: 'mattersList', label: 'View Matters', icon: <SearchIcon />, path: '/matters/list' });
                if (hasPermission('matters_all')) {
                    subItems.push({ id: 'newMatter', label: 'New Matter', icon: <ClientsIcon />, path: '/matters/new' });
                }
            }

            if (subItems.length > 0) {
                items.push({
                    id: 'clientsMatters',
                    icon: <ClientsIcon />,
                    label: 'Clients & Matters',
                    subItems: subItems
                });
            }
        }

        // Check Permissions for Time Tab
        if (!permissions.includes('time_none')) {
            const timeSubItems = [];

            if (hasPermission('time_write')) {
                timeSubItems.push({ id: 'timeEntry', label: 'Time Entry', icon: <TimeEntryIcon />, path: '/time/entry' });
            }
            if (hasPermission('time_read')) {
                timeSubItems.push({ id: 'timeSearch', label: 'Search Time', icon: <SearchIcon />, path: '/time/search' });
            }

            if (timeSubItems.length > 0) {
                items.push({
                    id: 'time',
                    icon: <TimeIcon />,
                    label: 'Time',
                    subItems: timeSubItems
                });
            }
        }

        // Check Permissions for Expense Tab
        if (!permissions.includes('expense_none')) {
            const expenseSubItems = [];

            if (hasPermission('expense_write')) {
                expenseSubItems.push({ id: 'newExpense', label: 'New Expense', icon: <NewExpenseIcon />, path: '/expense/new' });
            }
            if (hasPermission('expense_read')) {
                expenseSubItems.push({ id: 'searchExpense', label: 'Search Expenses', icon: <SearchIcon />, path: '/expense/search' });
            }

            if (expenseSubItems.length > 0) {
                items.push({
                    id: 'expense',
                    icon: <ExpenseIcon />,
                    label: 'Expense',
                    subItems: expenseSubItems
                });
            }
        }

        // Check Permissions for Payments Tab
        if (!permissions.includes('payments_none')) {
            const paymentSubItems = [];

            if (hasPermission('payments_write')) {
                paymentSubItems.push(
                    { id: 'makePayment', label: 'Make Payment', icon: <MakePaymentIcon />, path: '/payments/new' },
                    { id: 'trust', label: 'Trust Account', icon: <TrustIcon />, path: '/payments/trust' },
                    { id: 'replenish', label: 'Replenish', icon: <ReplenishIcon />, path: '/payments/replenish' }
                );
            }
            if (hasPermission('payments_read')) {
                paymentSubItems.push({ id: 'searchPayments', label: 'Search Payments', icon: <SearchIcon />, path: '/payments/search' });
            }

            if (paymentSubItems.length > 0) {
                items.push({
                    id: 'payments',
                    icon: <PaymentIcon />,
                    label: 'Payments',
                    subItems: paymentSubItems
                });
            }
        }

        // Check Permissions for Invoices Tab
        if (hasPermission('invoice_all')) {
            items.push({
                id: 'invoices',
                icon: <InvoiceIcon />,
                label: 'Invoices',
                subItems: [
                    { id: 'drafts', label: 'Draft Invoices', icon: <InvoiceIcon />, path: '/invoices/drafts' },
                    { id: 'sent', label: 'Sent Invoices', icon: <InvoiceIcon />, path: '/invoices/sent' }
                ]
            });
        }

        if (!permissions.includes('reports_none')) {
            const reportSubItems = [];

            if (hasPermission('reports_all') || hasPermission('reports_time')) {
                reportSubItems.push({ id: 'timeReports', label: 'Time Reports', icon: <TimeIcon />, path: '/reports/time' });
            }
            if (hasPermission('reports_all') || hasPermission('reports_expense')) {
                reportSubItems.push({ id: 'expenseReports', label: 'Expense Reports', icon: <ExpenseIcon />, path: '/reports/expense' });
            }
            if (hasPermission('reports_all') || hasPermission('reports_invoice')) {
                reportSubItems.push({ id: 'invoiceReports', label: 'Invoice Reports', icon: <InvoiceIcon />, path: '/reports/invoice' });
            }
            if (hasPermission('reports_all') || hasPermission('reports_commission')) {
                reportSubItems.push({ id: 'commissionReports', label: 'Commission Reports', icon: <ReportsIcon />, path: '/reports/commission' });
            }

            if (reportSubItems.length > 0) {
                items.push({
                    id: 'reports',
                    icon: <ReportsIcon />,
                    label: 'Reports',
                    subItems: reportSubItems
                });
            }
        }

        return items;
    };


    const handleMainMenuClick = (menuId) => {
        setSelectedMainMenu(menuId === selectedMainMenu ? null : menuId);
        if (isMobile) setMobileOpen(false);
    };

    const handleContentChange = (path) => {
        setSelectedPath(path);
        if (isMobile) setMobileOpen(false);
    };

    const menuItems = getMenuItems();

    const mainSidebarContent = (
        <Box sx={{
            width: 72,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 1,
            gap: 1,
            bgcolor: 'background.paper'
        }}>
            {menuItems.map((item) => (
                <IconButton
                    key={item.id}
                    onClick={() => handleMainMenuClick(item.id)}
                    sx={{
                        width: 48,
                        height: 48,
                        borderRadius: '50%',
                        bgcolor: selectedMainMenu === item.id ? 'primary.main' : 'transparent',
                        color: selectedMainMenu === item.id ?
                            (theme) => theme.palette.mode === 'light' ? 'white' : 'black' :
                            'text.primary',
                        '&:hover': {
                            bgcolor: 'action.hover',
                        }
                    }}
                >
                    {item.icon}
                </IconButton>
            ))}
        </Box>
    );

    const subMenuContent = selectedMainMenu && (
        <Box sx={{
            width: 240,
            bgcolor: 'background.paper',
            height: '100vh'
        }}>
            <Typography variant="h6" sx={{ p: 2 }}>
                {menuItems.find(item => item.id === selectedMainMenu)?.label}
            </Typography>
            <List>
                {menuItems
                    .find(item => item.id === selectedMainMenu)
                    ?.subItems?.map((subItem) => (
                        <ListItemButton
                            key={subItem.id}
                            onClick={() => handleContentChange(subItem.path)}
                            selected={selectedPath === subItem.path}
                            sx={{
                                '&:hover': {
                                    bgcolor: 'action.hover',
                                }
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: 40 }}>{subItem.icon}</ListItemIcon>
                            <ListItemText primary={subItem.label} />
                        </ListItemButton>
                    ))}
            </List>
        </Box>
    );

    // const handleRefresh = async () => {
    //     const refreshToken = localStorage.getItem('refreshToken');
    //     const response = await accountService.refreshToken(refreshToken);
    //     const accessToken = response.data.accessToken;
    //     const newRefreshToken = response.data.refreshToken;
    //     console.log("REFRESHED TOKENS")
    //     console.log(response);
    // }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            bgcolor: 'background.default'
        }}>
            <AppBar isMobile={isMobile} onMenuClick={() => setMobileOpen(!mobileOpen)} />
            {/* <Button type='button' onClick={handleRefresh}>Refresh Token</Button> */}
            <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                {isMobile ? (
                    <Drawer
                        variant="temporary"
                        anchor="left"
                        open={mobileOpen}
                        onClose={() => setMobileOpen(false)}
                        ModalProps={{ keepMounted: true }}
                        sx={{
                            '& .MuiDrawer-paper': {
                                width: 312,
                                boxSizing: 'border-box',
                            },
                        }}
                    >
                        <Box sx={{ display: 'flex' }}>
                            {mainSidebarContent}
                            {subMenuContent}
                        </Box>
                    </Drawer>
                ) : (
                    <>
                        <Box component="nav" sx={{ display: 'flex' }}>
                            {mainSidebarContent}
                            {subMenuContent}
                        </Box>
                    </>
                )}
                <Box sx={{ flex: 1, p: 3, overflow: 'auto' }}>
                    <DashboardContent selectedPath={selectedPath} />
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;